import { forwardRef, ComponentProps, ElementType, Ref } from "react"
import { Squircle as RealSquircle } from "corner-smoothing"

type SquircleProps<T extends ElementType> = {
    as?: T
    cornerRadius: number
    borderWidth?: number
    topLeftCornerRadius?: number
    topRightCornerRadius?: number
} & ComponentProps<T>

export const Squircle = forwardRef(function Squircle<T extends ElementType = "div">(
    { as: Component = "div", cornerRadius, borderWidth, ...props }: SquircleProps<T>,
    ref: Ref<Element>,
) {
    const isSquircle = cornerRadius > 0

    if (isSquircle) {
        return (
            <RealSquircle
                as={Component}
                cornerRadius={cornerRadius}
                borderWidth={borderWidth}
                ref={ref}
                {...props}
            />
        )
    }

    return <Component ref={ref} {...props} />
}) as <T extends ElementType = "div">(props: SquircleProps<T> & { ref?: Ref<Element> }) => JSX.Element

