import React, { useRef } from "react"
import cn from "classnames"
import s from "./InputField.module.scss"
import CountryCodeSelect from "../send-sms/CountryCodeSelect"
import { getTranslation } from "src/lib/translations"
import { useSettings } from "src/layout/page/settingsContext"
import { Squircle } from "src/components/squircle/Squircle"
// import { getTranslation } from "src/lib/translations"

interface InputFieldProps {
    type: "text" | "number" | "tel" | "email" | "select"
    name: string
    label?: string
    placeholder?: string
    value?: string
    phoneValue?: string[]
    className?: string
    pill?: boolean
    onChange?: (value: string) => void
    // useState hook for phoneValue
    setPhone?: React.Dispatch<React.SetStateAction<string[]>>
    children?: React.ReactNode
    required?: boolean
}


export default function InputField({ type, label, placeholder, name, value, phoneValue, children, onChange, setPhone, className, required = false, pill = false }: InputFieldProps): JSX.Element {
    const { lang } = useSettings()
    const t = getTranslation("downloadOverlay", lang)
    const phoneInputRef = useRef<HTMLInputElement>()

    return (
        <div className={cn(s["input-field"], className)}>
            {label && (
                <label className={s["input-field__label"]} htmlFor={name}>{label}</label>
            )}

            <Squircle cornerRadius={pill ? 24 : 0} borderWidth={1} className={cn(s["input-field__container"], s[`input-field__container--type-${type}`], pill ? s["input-field__container--pill"] : null)}>
                {name === "phone" && (
                    <div className={s["input-field__phone-prefix"]}>
                        <CountryCodeSelect phoneInputRef={phoneInputRef} countryCode={phoneValue?.[0]} setCountryCode={(newCountryCode => { setPhone((oldPhoneValue) => ([newCountryCode, oldPhoneValue[1]])) })} required={required} />
                    </div>
                )}
                {type === "select" && (
                    <select className={cn(s["input-field__input"])} value={value} onChange={(e) => onChange(e.target.value)} id={name} name={name} required={required}>
                        {children}
                    </select>
                )}
                {type !== "select" && (
                    <>
                        <input
                            className={cn(s["input-field__input"], name === "phone" ? s["input-field__input--phone"] : "")}
                            type={type}
                            name={name}
                            id={name}
                            placeholder={placeholder}
                            value={name == "phone" ? phoneValue[1] : value}
                            {...(type === "number" ? { pattern: "[0-9]*" } : {})}
                            {...(type === "tel" ? {
                                pattern: phoneValue?.[0] === "+45" ? "[0-9]{8,8}" : (phoneValue?.[0] === "+46" ? "[0-9]{7,13}" : "[0-9]{6,15}"),
                                title: phoneValue?.[0] === "+45" ? t.phoneInputInvalidTipDanishPhone : t.phoneInputInvalidTip,
                            } : {})}
                            {...(name === "phone" ? { ref: phoneInputRef } : {})}
                            onChange={(e) => typeof setPhone === "function" ? setPhone(sanitizePhoneValue([phoneValue?.[0], e.target.value])) : (typeof onChange === "function" ? onChange(e.target.value) : null)}
                            required={required}
                        />
                        {children ? children : null}
                    </>
                )}
            </Squircle>
        </div>
    )
}


function sanitizePhoneValue([countryCodeInput, phoneInput]: [string, string]) {
    if (!phoneInput) return [countryCodeInput, ""]

    const threeDigitCountryCodes = ["+29", "+35", "+37", "+38", "+42"] // Country codes that start with one of these are actual 3 digit (example: Ireland is +353)
    const phoneStartsWithThreeDigitCountryCode = threeDigitCountryCodes.find(cc => phoneInput.startsWith(cc))
    const countryCodeLength = phoneStartsWithThreeDigitCountryCode ? 4 : 3
    const phoneInputHasCountryCode = phoneInput.startsWith("+") && phoneInput.length >= countryCodeLength
    const parsedCountryCode = phoneInputHasCountryCode ? phoneInput.slice(0, countryCodeLength) : countryCodeInput
    const cleanPhone = (phoneInputHasCountryCode ? phoneInput.slice(countryCodeLength) : phoneInput).replace(/[^+\d]/g, "")

    return [parsedCountryCode, cleanPhone]
}
